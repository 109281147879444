import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import anime from 'animejs';

import COLORS from '../styles/colors';
import { randomInt } from '../utils/math';

const SIZE = 200;

const SVG = styled.svg`
  overflow: visible;
  & * {
    transform-box: fill-box;
  }
  & .multiply {
    mix-blend-mode: multiply;
  }
  & .eyes,
  & .eyebrows * {
    transform-origin: center;
  }

  & .mouth * {
    transform-origin: center;
  }

  & .glow {
    filter: drop-shadow(0px -10px 10px ${COLORS.white.opacity(0.6)});
  }
`;

const CLOTHING = [
  `M0 150 L70 150 L100 170 L130 150 L200 150 L200 200 L0 200 Z`,
  `M0 150 L70 150 C70 150 80 170 100 170 C120 170 130 150 130 150 L200 150 L200 200 L0 200 Z`,
  `M0 170 L20 170 C20 170 24 184 100 184 C176 184 180 170 180 170 L200 170 L200 200 L0 200 Z`,
  `M0 150 L70 150 C70 150 80 190 180 190 L200 100 L200 200 L0 200 Z`,
  `M0 150 L70 150 C70 150 80 170 100 180 C120 170 130 150 130 150 L200 150 L200 200 L0 200 Z`,
  `M0 150 L60 150 C60 150 70 170 100 170 C130 170 140 150 140 150 L200 150 L200 200 L0 200 Z`,
];

const HAIR_STYLE = [
  {
    front:
      'M100 55 C88 55 76 53 71 52 L63 90 C63 90 61 63 61 53 C61 45 73 34 102 34 C131 34 139 47 139 54 C139 61 136 92 136 92 L127 52 C122 53 112 55 100 55Z',
    back: 'M101 29 C102 23 106 19 112 19 C117 19 122 23 122 29 C122 29 122 29 122 29 C123 27 126 26 128 26 C134 26 138 31 138 36 C138 38 138 39 137 41 C137 41 138 41 138 41 C144 41 148 45 148 51 C148 56 144 61 138 61 C137 69 137 81 136 85 C129.371 113.322 112.936 117.5 100.468 117.5C88 117.5 72.9655 110.822 64.4824 85.6609C63.0795 81.5 61.9903 70 61.8454 61.1808C56.3947 61.1033 52 56.6607 52 51.1915C52 45.674 56.4728 41.2012 61.9903 41.2012C63.2711 41.2012 64.4955 41.4422 65.6207 41.8813C64.7249 40.3837 64.21 38.6319 64.21 36.7599C64.21 31.2424 68.6828 26.7695 74.2003 26.7695C77.4187 26.7695 80.2816 28.2913 82.1087 30.6546C82.0179 30.1134 81.9707 29.5574 81.9707 28.9903C81.9707 23.4728 86.4435 19 91.9611 19C97.4786 19 101.951 23.4728 101.951 28.9903Z',
  },
  {
    front:
      'M121 54C124 66 132 74 138 78C142 80 146 81 146 67C146 56 133 24 100 24C67 24 55 57 55 66C55 71 58 72 63 72C74 72 100 70 121 54Z',
    back: 'M100 112C125 112 145 92 145 67C145 42 125 22 100 22C75 22 55 42 55 67C55 92 75 112 100 112Z',
  },
  {
    front:
      'M121 54 C124 66 132 74 138 78C142 80 146 81 146 66 C146 56 133 24 100 24 C67 24 55 57 55 66 C55 71 58 72 63 72 C74 72 100 70 121 54Z',
    back: 'M102 161 C43 161 40 148 36 138C31 119 54 89 55 68 C55 52 72 24 102 24 C131 24 146 60 146 65C146 91 164 112 164 138 C165 144 160 161 101 161Z',
  },
  {
    back: 'M138 153 C116 153 119 145 101 144 C86 144 76 145 56 148 C36 152 34 131 37 122 C40 113 53 109 46 87 C38 65 44 55 53 47 C62 40 66 32 77 24 C87 17 90 27 117 26 C143 25 138 39 151 53 C163 67 162 74 159 96 C157 118 154 113 159 126 C165 140 160 153 138 153Z',
    front:
      'M130 63 C130 71 134 80 136 83 C143 77 153 63 146 49 C136 32 120 31 102 30 C84 28 67 39 58 51 C50 63 50 80 56 84 C62 89 75 86 77 78 C78 71 88 67 99 68 C110 69 114 60 116 57 C119 53 130 53 130 63Z',
  },
  {
    front:
      'M77 47 C77 37 86 37 92 27 C98 18 118 24 118 35 C118 47 112 40 102 54 C91 67 76 58 77 47Z',
    back: 'M140 60 C141 76 139 78 136 93 C133 108 65 107 63 90 C60 74 59 65 61 54 C64 38 78 36 93 34 C93 34 112 34 120 36 C128 38 139 45 140 61Z',
  },
  {
    back: 'M57 67 C57 84 61 93 63 96 C65 102 75 116 97.5 116 C119 116 131 102 134 96 C136 91 139 79 139 67 139 51 128 35 97 35 C66 35 57 45 57 67Z',
    front:
      'M63 73 V96 C61 94 59 85 59 82 C58 79 58 71 58 64 C58 55 59 44 74 37 C74 30 82 21 94 21 C123 21 136 44 140 56 C139 63 138 78 138 82 C138 85 136 92 134 96 V73 L128 69 L126 57 C123 55 114 51 97 51 C80 51 71 56 69 59 L67 69 L63 73Z',
  },
  {
    back: '',
    front: '',
  },
];

const MOUTH = [
  'M-11 26 C-9 29 -4 32 3 29',
  'M-4 27 C-3 28 0 28 2 27',
  'M-0 27 C-6 36 4 36 0 27',
];

export type Avatar = {
  skin: string;
  eyebrows?: { width?: number; size?: number };
  eyes?: { distance?: number; size?: number };
  ears?: { size?: number };
  clothing: { style: number; color: string };
  lips: boolean;
  hair: { style: number; clip?: boolean; ponytail?: boolean };
  beard: boolean;
  glasses?: boolean;
  earrings?: boolean;
};

interface AnimatedAvatarProps extends React.HTMLAttributes<SVGSVGElement> {
  notification: boolean;
  editing: boolean;
  avatar: Avatar;
  width?: number;
  height?: number;
  circle?: boolean;
  circleColor?: string;
  x?: number;
  y?: number;
  canAnimate?: boolean;
}

export const DEFAULT_AVATAR_SETTINGS: Avatar = {
  skin: COLORS.white.css,
  eyebrows: { width: 2, size: 14 },
  eyes: { distance: 14, size: 4 },
  ears: { size: 9 },
  clothing: { style: 1, color: COLORS.shades.s300.css },
  lips: false,
  hair: { style: 1 },
  beard: false,
  glasses: false,
};

function AnimatedAvatar({
  notification = false,
  editing = true,
  avatar = DEFAULT_AVATAR_SETTINGS,
  circle,
  circleColor,
  canAnimate = true,
  ...props
}: AnimatedAvatarProps) {
  const container = useRef(null);
  const idle = useRef(null);
  const surprised = useRef(null);
  const busy = useRef(null);

  useEffect(() => {
    // IDLE
    idle.current = anime.timeline({
      autoplay: true,
      loop: true,
      direction: 'alternate',
    });

    const head = container.current.querySelector('.head-controller');
    const eyes = container.current.querySelector('.head-controller .eyes');
    const face = container.current.querySelector('.head-controller .face');
    const mouth = container.current.querySelector(
      '.head-controller .mouth path'
    );
    const lips = container.current.querySelector(
      '.head-controller .mouth .lips'
    );
    const ears = container.current.querySelector('.head-controller .ears');
    const hairFront = container.current.querySelector('.hair-front');
    const beard = container.current.querySelector('.head-controller .beard');
    const hairBack = container.current.querySelector('.hair-back');
    const neckShadow = container.current.querySelector('.neck-shadow');
    const ponytail = container.current.querySelector('.ponytail');
    const eyebrows = container.current.querySelector(
      '.head-controller .eyebrows'
    );

    const leftEyebrow = eyebrows.children[0];
    const rightEyebrow = eyebrows.children[1];

    const laptop = container.current.querySelector('.laptop');

    const reset = (timeline, duration = 500, wait = 2500) => {
      timeline.add(
        {
          targets: [
            head,
            eyes,
            face,
            ears,
            hairFront,
            hairBack,
            eyebrows,
            leftEyebrow,
            rightEyebrow,
            beard,
            neckShadow,
            lips,
            ponytail,
            laptop,
          ].filter(Boolean),
          translateY: 0,
          translateX: 0,
          rotate: 0,
          scaleX: 1,
          scaleY: 1,
          easing: 'easeInOutQuart',
          duration,
        },
        wait
      );

      timeline.add(
        {
          targets: mouth,
          d: MOUTH[0],
          fill: 'rgba(0,0,0,0)',
          duration,
          easing: 'easeOutElastic(1.5, 0.5)',
        },
        wait
      );
    };

    idle.current.add(
      {
        targets: eyes,
        scaleY: 0,
        easing: 'easeInOutQuart',
        duration: 200,
        delay: () => randomInt(3, 6) * 1000,
      },
      0
    );

    // NOTIFICATION
    surprised.current = anime.timeline({
      autoplay: false,
      complete: () => {
        // go back to idle animation
        idle.current.play();
      },
    });

    // move face to top right
    surprised.current.add(
      {
        targets: face,
        translateX: 10,
        translateY: -5,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    surprised.current.add(
      {
        targets: beard,
        translateX: 10,
        translateY: -5,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    surprised.current.add(
      {
        targets: mouth,
        d: MOUTH[2],
        fill: '#2F2F2F',
        duration: 500,
        easing: 'easeOutElastic(1.5, 0.5)',
      },
      0
    );

    surprised.current.add(
      {
        targets: lips,
        translateX: 3,
        translateY: 1,
        scaleX: 0.6,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    surprised.current.add(
      {
        targets: ears,
        translateX: -5,
        translateY: 5,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    surprised.current.add(
      {
        targets: hairFront,
        translateX: 2,
        translateY: -5,
        duration: 1500,
        easing: 'easeOutElastic(1, 0.4)',
      },
      0
    );

    surprised.current.add(
      {
        targets: hairBack,
        translateX: -6,
        translateY: 3,
        duration: 1500,
        easing: 'easeOutElastic(2, 0.4)',
      },
      0
    );

    surprised.current.add(
      {
        targets: ponytail,
        translateX: -6,
        translateY: 3,
        duration: 1500,
        easing: 'easeOutElastic(2, 0.4)',
      },
      0
    );

    surprised.current.add(
      {
        targets: eyebrows,
        translateY: -5,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    reset(surprised.current, 1000, 500);

    // EDITING
    busy.current = anime.timeline({
      autoplay: false,
      complete: () => {
        // go back to idle animation
        idle.current.play();
      },
    });

    busy.current.add(
      {
        targets: laptop,
        translateY: -90,
        duration: 1200,
        delay: 200,
        easing: 'easeOutElastic(1.5, 0.5)',
      },
      0
    );

    busy.current.add(
      {
        targets: head,
        translateY: 10,
        duration: 1000,
        easing: 'easeOutElastic(1.5, 0.5)',
      },
      0
    );

    busy.current.add(
      {
        targets: mouth,
        d: MOUTH[1],
        duration: 1000,
        easing: 'easeOutElastic(1.5, 0.5)',
      },
      0
    );

    busy.current.add(
      {
        targets: lips,
        translateX: 3,
        translateY: -2,
        scaleY: 0.6,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    busy.current.add(
      {
        targets: beard,
        translateY: 10,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    busy.current.add(
      {
        targets: neckShadow,
        translateY: 5,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    busy.current.add(
      {
        targets: face,
        translateY: 10,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    busy.current.add(
      {
        targets: ears,
        translateY: -10,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    busy.current.add(
      {
        targets: hairFront,
        translateY: 15,
        duration: 1000,
        easing: 'easeOutElastic(2, 0.5)',
      },
      0
    );

    busy.current.add(
      {
        targets: hairBack,
        translateY: -3,
        duration: 1000,
        easing: 'easeOutElastic(2, 0.5)',
      },
      0
    );

    busy.current.add(
      {
        targets: ponytail,
        translateY: -3,
        duration: 1500,
        easing: 'easeOutElastic(2, 0.4)',
      },
      0
    );

    busy.current.add(
      {
        targets: eyebrows,
        translateY: 5,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    busy.current.add(
      {
        targets: leftEyebrow,
        rotate: 20,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    busy.current.add(
      {
        targets: rightEyebrow,
        rotate: -20,
        duration: 500,
        easing: 'easeOutElastic(1.5, 1)',
      },
      0
    );

    reset(busy.current, 500, 2500);
  }, []);

  useEffect(() => {
    busy.current.seek(0);
    surprised.current.seek(0);
    if (notification) {
      surprised.current.play();
      busy.current.pause();
    } else if (editing) {
      surprised.current.pause();
      busy.current.play();
    }
  }, [notification, editing]);

  useEffect(() => {
    if (canAnimate) {
      idle.current.play();
    } else {
      idle.current.pause();
    }
  }, [canAnimate]);

  return (
    <SVG
      width={SIZE}
      height={SIZE}
      viewBox={`0 0 ${SIZE} ${SIZE}`}
      xmlns="http://www.w3.org/2000/svg"
      ref={container}
      {...props}
    >
      <defs>
        <g id="ear">
          <circle
            cx={avatar.ears.size}
            cy={avatar.ears.size}
            r={avatar.ears.size}
          />
          <line
            x1={6}
            y1={8}
            x2={avatar.ears.size * 2 - 4}
            y2={avatar.ears.size * 2 - 6}
          />
        </g>

        <circle id="eye" cx="0" cy="0" r={avatar.eyes.size} stroke="none" />

        <line
          id="eyebrow"
          x1={-avatar.eyebrows.size / 2}
          y1={0}
          x2={avatar.eyebrows.size / 2}
          y2={0}
        />

        <circle id="circle" r={SIZE / 2} cx={SIZE / 2} cy={SIZE / 2} />

        <clipPath id="clip-face-shape">
          <path
            d="M0 55 C23 55 33 33 35 13 C37 -7 36 -44 0 -44 C-36 -44 -37 -7 -35 13 C-33 33 -22 55 0 55 Z"
            id="face-shape"
          />
        </clipPath>
        <clipPath id="clip-body">
          <path
            d="M84.0934 154.56L87.1621 120.365H111.486L115.271 154.425C129.73 157.444 145.64 163.546 151.748 168.113C165.243 178.203 172.872 193.488 175 199.87H25C27.1277 193.488 34.7568 178.203 48.2523 168.113C54.2707 163.613 69.8065 157.623 84.0934 154.56Z"
            id="body"
          />
        </clipPath>
        <clipPath id="clip-circle">
          <use href="#circle" fill="white" stroke="none" />
        </clipPath>
      </defs>

      <g
        stroke="#2F2F2F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath={circle ? 'url(#clip-circle)' : ''}
      >
        <g className="hair-back">
          <g className="ponytail">
            {avatar.hair.ponytail && (
              <>
                <circle cx={100} cy={22} r={22} fill="#2F2F2F" />
                <circle
                  cx={100}
                  cy={38}
                  r={22}
                  fill={COLORS.error.regular.css}
                />
              </>
            )}
          </g>
          <path d={HAIR_STYLE[avatar.hair.style].back} fill="#2F2F2F" />
        </g>
        <use href="#body" fill={avatar.skin} />
        <g className="shirt" clipPath="url(#clip-body)" strokeWidth="2">
          <path
            d={CLOTHING[avatar.clothing.style]}
            fill={avatar.clothing.color}
          />
        </g>
        <g clipPath="url(#clip-body)" className="multiply">
          <circle
            cx={144}
            cy={85}
            r={72}
            fill={'#7968E1'}
            stroke="none"
            className="neck-shadow"
          />
        </g>
        <use href="#body" fill="none" />
        <g className="head-controller">
          <g className="head" transform={`translate(${SIZE / 2}, 82)`}>
            <g className="ears">
              <g
                className="ears-inner"
                transform="translate(0,9)"
                fill={avatar.skin}
              >
                <use
                  href="#ear"
                  transform={`translate(-${36 + avatar.ears.size}, 0)`}
                />
                <use
                  href="#ear"
                  transform={`scale(-1,1) translate(-${
                    36 + avatar.ears.size
                  }, 0)`}
                />
              </g>
              {avatar.earrings && (
                <g
                  className="earrings"
                  transform="translate(0,35)"
                  fill="none"
                  stroke={COLORS.white.css}
                >
                  <circle cx={0} cy={0} r={10} transform="translate(-40, 0)" />
                  <circle cx={0} cy={0} r={10} transform="translate(40, 0)" />
                </g>
              )}
            </g>
            <use href="#face-shape" fill={avatar.skin} />

            <g clipPath={'url(#clip-face-shape)'}>
              <g className="beard">
                {avatar.beard && (
                  <path
                    d="M-26 17C-30 13 -28 0 -35 0C-42 0 -52 4 -52 22C-52 39 -30 64 0 64C30 64 49 41 49 22C49 2 40 0 35 0C26 0 28 15 21 18C13 22 14 15 0 15C-15 15 -21 22 -26 17Z"
                    stroke="none"
                    fill="#2F2F2F"
                    transform="translate(0,5)"
                  />
                )}
              </g>
            </g>

            <g className="face">
              <g className="face-inner" transform="translate(0,3)">
                <g className="eyes" transform="translate(0,0)" fill="#2F2F2F">
                  <use
                    href="#eye"
                    transform={`translate(-${avatar.eyes.distance},0)`}
                  />
                  <use
                    href="#eye"
                    transform={`translate(${avatar.eyes.distance},0)`}
                  />
                </g>

                <g className="glasses" fill="none">
                  {avatar.glasses && (
                    <g transform="translate(-35,-8)">
                      <circle cx="55" cy="15" r="15" />
                      <circle cx="15" cy="15" r="15" />
                      <path d="M29 13C29 13 32 10 35 10C38 10 41 13 41 13" />
                    </g>
                  )}
                </g>

                <g className="eyebrows" strokeWidth={avatar.eyebrows.width}>
                  <g className="left-eyebrow">
                    <use
                      href="#eyebrow"
                      transform={`translate(-${
                        avatar.eyes.distance + 3
                      },-12) rotate(-20)`}
                    />
                  </g>
                  <g className="right-eyebrow">
                    <use
                      href="#eyebrow"
                      transform={`translate(${
                        avatar.eyes.distance + 3
                      },-12) rotate(20)`}
                    />
                  </g>
                </g>

                <path d="M1 6 l1 13 l-5 0" fill="none" className="nose" />
                <g className="mouth">
                  <g className="lips">
                    {avatar.lips && (
                      <ellipse
                        cx="-4"
                        cy="29"
                        rx="6"
                        ry="3"
                        fill={COLORS.error.light.css}
                        stroke="none"
                        transform="rotate(8)"
                      />
                    )}
                  </g>
                  <path
                    d={MOUTH[0]}
                    fill="transparent"
                    stroke={avatar.beard ? COLORS.white.css : '#2F2F2F'}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g clipPath={avatar.hair.clip && 'url(#clip-face-shape)'}>
          <g className="hair-front">
            <path d={HAIR_STYLE[avatar.hair.style].front} fill="#2F2F2F" />
          </g>
        </g>
        <g className="laptop">
          <g transform={`translate(0,${SIZE + 50})`}>
            <rect
              width="130"
              height="100"
              x={35}
              y={0}
              fill={COLORS.shades.s200.css}
              stroke={'#2F2F2F'}
              rx={10}
              className="glow"
            />
            <circle
              cx={SIZE / 2}
              cy={30}
              r={8}
              fill={COLORS.white.css}
              stroke={'#2F2F2F'}
            />
          </g>
        </g>
      </g>
      {circle && (
        <use
          href="#circle"
          fill="none"
          stroke={circleColor || avatar.clothing.color}
          strokeWidth="10"
        />
      )}
    </SVG>
  );
}

export default memo(AnimatedAvatar);
